import React, { useCallback, useEffect, useRef } from 'react';

import { v4 } from 'uuid';
import {
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Tooltip,
} from '@material-ui/core';

import { formatCurrencyAsText } from '~/utils/functions';

import DefaultLoader from '~/components/DefaultLoader';
import { InputMoney } from '~/components/NovosInputs';
import { HistoricoProduto } from '~/components/HistoricoProduto';
import { Container } from '../../styles';

import { useAlteracaoPrecoEntradaFornecedor } from '../../AlteracaoPrecoEntradaFornecedorContext';
import { NFItens } from '../../types';
import { toast } from 'react-toastify';

const TableContainerComponent: React.FC = () => {
  const {
    register,
    control,
    errors,
    loadingSug,
    itensNF,
    handleRowClick,
    selectedRowIndex,
    onKeyUpInputMoney,
    user,
  } = useAlteracaoPrecoEntradaFornecedor();

  const inputContainerRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    setTimeout(() => {
      if (inputContainerRef.current && !loadingSug && itensNF.length > 0) {
        const { cod_produto } = itensNF[0];
        const inputName = `venda_praticar_${cod_produto}`;

        const firstInput: HTMLInputElement | null =
          inputContainerRef.current.querySelector(`[name="${inputName}"]`);

        if (firstInput) firstInput.focus();
      }
    }, 500);
  }, [itensNF, loadingSug]);

  const variacaoColor = useCallback((variacao: string) => {
    const value = parseFloat(variacao);
    if (value > 0) return 'red';
    if (value < 0) return 'blue';
    return 'black';
  }, []);

  const copyToClipboard = useCallback((text: string) => {
    if (!navigator.clipboard) return;

    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast.success('Código copiado para área de transferência');
      })
      .catch(() => {
        toast.warning('Não foi possível copiar');
      });
  }, []);

  return (
    <Paper>
      {loadingSug && (
        <Container>
          <DefaultLoader />
        </Container>
      )}
      {!loadingSug && (
        <div className="col-sm-12 col-md-12 mt-4">
          <TableContainer
            style={{ maxHeight: 400, minHeight: 400, position: 'relative' }}
          >
            <Table stickyHeader>
              <TableHead>
                <TableRow>
                  <TableCell className="cellModify">Item</TableCell>
                  <TableCell
                    className="cellModify"
                    align="center"
                    style={{ minWidth: '100px' }}
                  >
                    Código
                  </TableCell>
                  <TableCell
                    className="cellModify"
                    style={{
                      minWidth: '18.75rem',
                    }}
                  >
                    {' '}
                    Descrição
                  </TableCell>
                  <TableCell align="center" className="cellModify">
                    Alt?
                  </TableCell>
                  <TableCell align="center" className="cellModify">
                    Variação
                  </TableCell>
                  <TableCell align="center" className="cellModify">
                    Venda
                  </TableCell>
                  {/* <TableCell
                    className="cellModify"
                    align="center"
                    style={{
                      minWidth: '150px',
                    }}
                  >
                    Venda PDV
                  </TableCell> */}
                  <TableCell
                    className="cellModify"
                    align="center"
                    style={{
                      minWidth: '150px',
                    }}
                  >
                    Venda Praticar
                  </TableCell>
                  <TableCell
                    className="cellModify"
                    style={{
                      minWidth: '120px',
                    }}
                    align="center"
                  >
                    Sugestão
                  </TableCell>
                  <TableCell align="center" className="cellModify">
                    Bonif?
                  </TableCell>
                  <TableCell
                    className="cellModify"
                    style={{
                      minWidth: '150px',
                    }}
                    align="center"
                  >
                    Histórico
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="tableBottom" style={{ height: '1.25rem' }}>
                {itensNF.map((item: NFItens, index: number) => {
                  return (
                    <TableRow
                      key={v4()}
                      onClick={() => {
                        handleRowClick(index, item, true);
                      }}
                      style={{
                        backgroundColor:
                          selectedRowIndex === index ? 'lightblue' : 'white',
                      }}
                    >
                      <TableCell>{item.num_item}</TableCell>
                      <TableCell
                        style={{ cursor: 'pointer' }}
                        onClick={() => copyToClipboard(item.cod_produto)}
                        align="center"
                      >
                        {item.cod_produto}
                      </TableCell>
                      <Tooltip
                        title={
                          item.des_produto.length > 17 ? (
                            <span style={{ fontSize: '0.8125rem' }}>
                              {item.des_produto}
                            </span>
                          ) : (
                            ''
                          )
                        }
                        arrow
                        PopperProps={{ style: { marginTop: -35 } }}
                      >
                        <TableCell
                          style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: '18.75rem',
                          }}
                        >
                          {item.des_produto}
                        </TableCell>
                      </Tooltip>
                      <TableCell
                        style={{
                          color: item.flg_custo_alterado ? 'red' : '#212121',
                        }}
                        align="center"
                      >
                        {item.flg_custo_alterado ? 'Sim' : 'Não'}
                      </TableCell>
                      <TableCell
                        style={{
                          color: variacaoColor(
                            formatCurrencyAsText(
                              Number(item.per_variacao_custo),
                            ),
                          ),
                        }}
                        align="center"
                      >
                        {formatCurrencyAsText(Number(item.per_variacao_custo))}%
                      </TableCell>
                      <TableCell
                        style={{
                          color: item.val_venda === 0 ? 'red' : 'black',
                        }}
                        align="center"
                      >
                        {formatCurrencyAsText(item.val_venda)}
                      </TableCell>
                      {/* <TableCell align="center">0</TableCell> */}
                      <TableCell
                        ref={index === 0 ? inputContainerRef : undefined}
                      >
                        <InputMoney
                          className="form-control moneyTable"
                          label=""
                          placeholder="0,00"
                          maxLength={10}
                          onKeyUp={(value) => {
                            onKeyUpInputMoney(
                              item,
                              value.target,
                              `venda_praticar_${item.cod_produto}`,
                            );
                          }}
                          min={0.01}
                          max={999999.01}
                          name={`venda_praticar_${item.cod_produto}`}
                          register={register}
                          isError={!errors}
                          showIcon={false}
                          control={control}
                        />
                      </TableCell>
                      <TableCell align="center">
                        {formatCurrencyAsText(Number(item.calcSugVda))}
                      </TableCell>
                      <TableCell align="center">
                        {item.flg_bonificado ? 'Sim' : 'Não'}
                      </TableCell>
                      <TableCell className="svg">
                        <HistoricoProduto
                          codProduto={item.cod_produto}
                          codLoja={item.cod_loja}
                          user={user}
                          hasIcone
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
              {itensNF.length === 0 && (
                <div
                  style={{
                    left: '0',
                    right: '0',
                    marginTop: '0px',
                    textAlign: 'center',
                    position: 'absolute',
                  }}
                >
                  Nenhum registro encontrado
                </div>
              )}
            </Table>
          </TableContainer>
        </div>
      )}
    </Paper>
  );
};

export default TableContainerComponent;
