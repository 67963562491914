import React, {
  createContext,
  useCallback,
  useContext,
  useState,
  useEffect,
} from 'react';

import {
  AlteracaoPrecoEntradaFornecedorContextData,
  ProviderAlteracaoPrecoEntradaFornecedorProps,
  NFItens,
  CachePesquisaInfoProps,
  CachePesquisaDataProps,
  dataSearch,
} from './types';
import { useForm } from 'react-hook-form';
import nfdataDefault from './defaultData/nfdata.json';
import { GridRowParams } from '@material-ui/data-grid';
import useAuth from '~/hooks/useAuth';

import api from '~/services/api';
import { formatCurrencyAsText, transformAsCurrency } from '~/utils/functions';
import { toast } from 'react-toastify';
import { useQueryClient } from 'react-query';
import { CalculoMargem } from '~/utils/classes/CalculoMargem';

export const AlteracaoPrecoEntradaFornecedorContext = createContext(
  {} as AlteracaoPrecoEntradaFornecedorContextData,
);

export const AlteracaoPrecoEntradaFornecedorProvider: React.FC<
  ProviderAlteracaoPrecoEntradaFornecedorProps
> = ({ children }) => {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [showSearch, setShowSearch] = useState<boolean>(true);

  const [loja, setLoja] = useState<number | undefined>(undefined);
  const [fornecedor, setFornecedor] = useState<string>('');
  const [codFornecedor, setCodFornecedor] = useState<number | undefined>(
    undefined,
  );

  const [cnpj, setCnpj] = useState<number | undefined>(undefined);
  const [numNf, setNumNf] = useState<number | undefined>(undefined);
  const [entrada, setEntrada] = useState<string>('');
  const [itensNF, setItensNF] = useState<NFItens[]>([]);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number>(0);
  const [selectedRow, setSelectedRow] = useState<NFItens>(nfdataDefault);
  const [loadingCalc, setLoadingCalc] = useState<boolean>(true);
  const [loadingSug, setLoadingSug] = useState<boolean>(true);
  const [timeRowChange, setTimeRowChange] = useState<number>(0);
  const [conferida, setConferida] = useState<boolean>(false);
  const [codSeqNF, setCodSeqNF] = useState<number | undefined>(undefined);
  const [isMounted, setIsMounted] = useState(false);
  const [precoArredondamento, setPrecoArredondamento] = useState<any[]>([]);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [pdf, setPdf] = useState<any>();
  const [loadingPDF, setLoadingPDF] = useState<boolean>(false);

  let timeoutId: any = null;

  useEffect(() => {
    if (user) {
      setPrecoArredondamento(user.precos_arredondamentos);
    }
  }, [user]);

  const {
    register,
    control,
    reset,
    getValues,
    setValue,
    setFocus,
    getFieldState,
    formState: { errors },
  } = useForm({});

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const calcSugVda = useCallback(
    async (item: NFItens) => {
      const sugVda = await CalculoMargem.getValSugestaoVenda(
        item.val_custo_rep,
        item.val_custo_sem_imposto,
        item.val_margem,
        item.per_desp_op,
        item.per_icms_saida ?? 0,
        item.tipo_margem,
        item.cod_preco,
        item.per_fcp,
        item.per_pis_lj,
        item.per_cofins_lj,
        false,
        precoArredondamento,
      );

      const valSug = Number(sugVda);
      const custoRep = item.val_custo_rep;

      if (valSug < custoRep) return custoRep;
      return valSug;
    },
    [precoArredondamento],
  );

  const calcMargVdaAtual = useCallback(async (item: NFItens) => {
    const margVda = CalculoMargem.getValMargemVenda(
      item.tipo_margem,
      item.val_custo_rep,
      item.val_custo_sem_imposto,
      item.val_venda,
      item.val_imposto_debito,
      item.per_desp_op,
    );
    return margVda;
  }, []);

  const calcMargVdaCalculada = useCallback(
    async (item: NFItens, value?: string) => {
      let calcMarg;
      if (value) {
        let ValueConverted;
        if (value.includes(',')) {
          const convertedValue = value.replace(',', '');
          const convertedValueString = convertedValue.slice(-2);
          ValueConverted = `${convertedValue.slice(
            0,
            -2,
          )},${convertedValueString}`;
        } else {
          ValueConverted = `00,0${value}`;
        }
        calcMarg = ValueConverted;
      } else {
        calcMarg = getValues(`venda_praticar_${item.cod_produto}`);
      }

      if (calcMarg) {
        const margVda = CalculoMargem.getValMargemVenda(
          item.tipo_margem,
          item.val_custo_rep,
          item.val_custo_sem_imposto,
          transformAsCurrency(calcMarg),
          item.val_imposto_debito,
          item.per_desp_op,
        );
        return margVda;
      }
    },
    [getValues],
  );

  const getDateVenda = useCallback(async (cod_item_embalagem: number) => {
    const { data } = await api.get(
      `/preco-entrada/venda/${cod_item_embalagem}`,
    );
    if (data.success) {
      return data.data;
    }
  }, []);

  const variacao = useCallback((venda_atual: number, val_sugestao: number) => {
    let Result = 0.0;
    if (val_sugestao > 0.0) {
      Result = (100 * venda_atual) / val_sugestao - 100;
    }
    if (venda_atual === 0.0 && val_sugestao === 0.0) {
      Result = 0.0;
    }
    if (venda_atual === 0.0 || val_sugestao === 0.0) {
      Result = 100.0;
    }
    return Result;
  }, []);

  const perDivergenciaSugestao = useCallback(
    (val_venda_atual: number, val_sugestao: string) => {
      return variacao(val_venda_atual, Number(val_sugestao));
    },
    [variacao],
  );

  const handleRowClick = useCallback(
    async (rowId: number, item: NFItens, clickLinha: boolean) => {
      if (!isMounted) return;

      if (clickLinha) {
        if (selectedRowIndex === rowId) {
          return;
        }
      }

      setSelectedRowIndex(rowId);

      setLoadingCalc(true);
      const dateVenda: any = await getDateVenda(item.cod_item_embalagem);
      const calcMargVdaCalculadaValue = await calcMargVdaCalculada(item);
      const calcMargVdaAtualValue = await calcMargVdaAtual(item);
      item.calcMargVda = formatCurrencyAsText(
        Number(calcMargVdaCalculadaValue),
      );

      item.calcMargVdaAtual = calcMargVdaAtualValue;
      item.dta_ult_alt_preco_base = dateVenda[0]?.dta_ult_alt_preco_base;
      item.val_anterior_preco_base = dateVenda[0]?.val_anterior_preco_base;
      setValue('precoVenda', formatCurrencyAsText(Number(item.calcSugVda)));
      setValue(
        'markUp',
        item.val_margem.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      );
      item.divergenciaSugestao = perDivergenciaSugestao(
        item.val_venda,
        item.calcSugVda,
      );
      setValue(
        'selectedRowVal_margem',
        selectedRow.val_margem.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      );
      setSelectedRow(item);

      setTimeout(function () {
        setLoadingCalc(false);
        setFocus(`venda_praticar_${item.cod_produto}`);
      }, 700);
    },
    [
      calcMargVdaAtual,
      calcMargVdaCalculada,
      getDateVenda,
      perDivergenciaSugestao,
      selectedRow.val_margem,
      selectedRowIndex,
      setValue,
      isMounted,
      setFocus,
    ],
  );

  useEffect(() => {
    const isScreen =
      window.location.pathname === '/alteracao-precos-entrada-fornecedor';

    if (loadingSug || itensNF.length <= 0 || !isScreen) return;

    const handleTabPress = (event: KeyboardEvent) => {
      if (event.key === 'Tab' && event.shiftKey) {
        event.preventDefault();

        setSelectedRowIndex((prevIdx) => {
          const previousItem = (prevIdx - 1 + itensNF.length) % itensNF.length;
          const item = itensNF[previousItem];

          handleRowClick(previousItem, item, true);

          return previousItem;
        });
      } else if (event.key === 'Tab') {
        event.preventDefault();

        setSelectedRowIndex((prevIdx) => {
          const nextItem = (prevIdx + 1) % itensNF.length;
          const item = itensNF[nextItem];

          handleRowClick(nextItem, item, true);

          return nextItem;
        });
      }
    };
    window.addEventListener('keydown', handleTabPress);
    return () => window.removeEventListener('keydown', handleTabPress);
  }, [handleRowClick, itensNF, loadingSug, window.location.pathname]);

  const onRowClick = useCallback(
    async (param: GridRowParams) => {
      setLoader(true);
      const { row } = param;
      const {
        cod_fornecedor,
        cod_loja,
        des_fornecedor,
        dta_entrada,
        flg_conferida,
        num_cpf_cnpj,
        num_nf,
        cod_seq_nf,
        num_chave_acesso,
      } = row;

      setLoja(cod_loja);
      setConferida(flg_conferida);
      setCnpj(num_cpf_cnpj);
      setFornecedor(des_fornecedor);
      setCodFornecedor(cod_fornecedor);
      setValue('cod_fornecedor', cod_fornecedor);
      setValue('num_chave_acesso', num_chave_acesso);
      setValue('cod_loja', cod_loja);
      setNumNf(num_nf);
      setCodSeqNF(cod_seq_nf);
      setEntrada(dta_entrada);
      setLoadingSug(true);

      const { data } = await api.get(`/preco-entrada/${cod_seq_nf}`);
      if (data.success) {
        setLoadingSug(true);
        data.data.map(async (data2: any, index: number) => {
          const calcSugVdaResult: any = await calcSugVda(data2);
          data.data[index].calcSugVda = calcSugVdaResult;
          data.data[index].divergenciaSugestao = perDivergenciaSugestao(
            data.data[index].val_venda,
            calcSugVdaResult,
          );
        });
        setItensNF(data.data);
        if (data.data[0] === undefined) {
          setLoader(false);
          setShowSearch(true);
          return toast.warn(
            'Não foi possível acessar o registro, tente novamente ou entre em contato com o suporte',
          );
        }
        setSelectedRow(data.data[0]);
        handleRowClick(0, data.data[0], false);
        setSelectedRowIndex(0);
      }

      setTimeout(() => {
        setLoadingSug(false);
      }, 1000);

      setUpdate(true);
      setShowSearch(false);
      setLoader(false);
    },
    [calcSugVda, handleRowClick, perDivergenciaSugestao, setValue],
  );

  const resetFormData = useCallback(() => {
    setUpdate(false);
    reset();
  }, [reset]);

  const calcsimulado = useCallback(
    async (item: NFItens, origemEvent: string, value: any) => {
      if (origemEvent === 'Mark-up') {
        const sugVda = await CalculoMargem.getValSugestaoVenda(
          item.val_custo_rep,
          item.val_custo_sem_imposto,
          transformAsCurrency(value),
          item.per_desp_op,
          item.per_icms_saida ?? 0,
          item.tipo_margem,
          item.cod_preco,
          item.per_fcp,
          item.per_pis_lj,
          item.per_cofins_lj,
          false,
          precoArredondamento,
        );

        const valSug = Number(sugVda);
        const custoRep = item.val_custo_rep;

        const precoVenda = valSug < custoRep ? custoRep : valSug;

        setValue('precoVenda', formatCurrencyAsText(Number(precoVenda)));

        return precoVenda;
      }

      const margVda = CalculoMargem.getValMargemVenda(
        item.tipo_margem,
        item.val_custo_rep,
        item.val_custo_sem_imposto,
        transformAsCurrency(value),
        item.val_imposto_debito,
        item.per_desp_op,
      );

      setValue('markUp', margVda);
      return margVda;
    },
    [setValue, precoArredondamento],
  );

  const simulador = useCallback(
    (origemEvent: string, value: any, item: NFItens) => {
      const valueformat = formatCurrencyAsText(value.target.value);
      let valVenda;
      if (valueformat.length === 1) {
        const numeroComZero = `0${valueformat}`;
        const numeroFormatado = parseFloat(numeroComZero);
        let valorFormatado = numeroFormatado.toFixed(2);
        valorFormatado = valorFormatado.replace('.', ',');
        valorFormatado = valorFormatado.split(',').reverse().join(',');
        const valorFormatadoSplit = valorFormatado.split(',');
        const ValueConverted = `${valorFormatadoSplit[0]},0${valorFormatadoSplit[1]}`;
        valVenda = ValueConverted;
      } else {
        const convertedValue = valueformat.replace(',', '');
        const convertedValueString = convertedValue.slice(-2);
        const ValueConverted = `${convertedValue.slice(
          0,
          -2,
        )},${convertedValueString}`;
        valVenda = ValueConverted;
      }

      if (origemEvent === 'Mark-up') {
        if (transformAsCurrency(valVenda) <= 100) {
          calcsimulado(item, origemEvent, valVenda);
        }
      } else {
        calcsimulado(item, origemEvent, valVenda);
      }
    },
    [calcsimulado],
  );

  const handleRowChange = useCallback(
    async (item: NFItens, value: any, name: string) => {
      setLoadingCalc(true);
      if (timeRowChange !== 0) {
        setTimeRowChange(0);
      }
      setTimeRowChange(2000);
      const valueformat = formatCurrencyAsText(value.value);
      const calcMargVda = await calcMargVdaCalculada(item, valueformat);
      const MargVdaAtual = await calcMargVdaAtual(item);
      item.calcMargVda = formatCurrencyAsText(Number(calcMargVda));

      item.calcMargVdaAtual = MargVdaAtual;
      setValue(
        'markUp',
        item.val_margem.toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
      );
      setSelectedRow(item);
      setTimeout(() => {
        setValue('precoVenda', formatCurrencyAsText(Number(item.calcSugVda)));

        setLoadingCalc(false);
        setFocus(name);
      }, timeRowChange);
    },
    [calcMargVdaAtual, calcMargVdaCalculada, setFocus, setValue, timeRowChange],
  );

  const assumeValor = useCallback(() => {
    let precoVenda = getValues('precoVenda');
    let vendaPraticar = precoVenda;

    if (vendaPraticar.length > 6) {
      precoVenda = precoVenda.replace('.', '');
    }

    if (typeof precoVenda === 'string') {
      vendaPraticar = precoVenda.replace(',', '.');
    }

    setValue(
      `venda_praticar_${selectedRow.cod_produto}`,
      formatCurrencyAsText(Math.abs(Number(vendaPraticar))),
    );

    handleRowChange(
      selectedRow,
      Math.abs(Number(vendaPraticar)),
      `venda_praticar_${selectedRow.cod_produto}`,
    );
  }, [getValues, handleRowChange, selectedRow, setValue]);

  const nextItem = useCallback(() => {
    const cod_fornecedor = getValues('cod_fornecedor');
    const cachePesquisaInfo: CachePesquisaInfoProps | undefined =
      queryClient.getQueryData(`cacheLastSearch_${210}`) || undefined;
    const cachePesquisaData: CachePesquisaDataProps | undefined =
      queryClient.getQueryData([`cache_tela_210`, cachePesquisaInfo?.pag]) ||
      undefined;

    const nextItemIndex = cachePesquisaData?.data.findIndex(
      (item: dataSearch) => item.cod_fornecedor === cod_fornecedor,
    );

    let dataToRowOnClick: any = {
      row: cachePesquisaData?.data[nextItemIndex + 1],
    };

    if (!cachePesquisaData?.data[nextItemIndex + 1]) {
      if (cachePesquisaData?.data.length - 1 > 0) {
        dataToRowOnClick = { row: cachePesquisaData?.data[0] };
      } else {
        setTimeout(() => {
          setUpdate(false);
          setShowSearch(true);
        }, 500);

        return;
      }
    }

    onRowClick(dataToRowOnClick);
  }, [getValues, onRowClick, queryClient]);

  const validateNFConferida = useCallback(() => {
    const validateMap = itensNF.some((item: any, index: number) => {
      const val_new = getValues(`venda_praticar_${item.cod_produto}`);
      const val_convert = Number(val_new.replace(/[^0-9.-]+/g, '.'));
      return val_convert && val_convert !== item.val_venda;
    });
    return validateMap;
  }, [itensNF]);

  const descartarAlteracoes = useCallback(() => {
    reset();
    setValue('cod_fornecedor', codFornecedor);
    handleRowClick(0, itensNF[0], false);
  }, []);

  const marcarNFConferida = useCallback(async () => {
    const nfConferida = await api.put(
      `/preco-entrada/nf-conferida/${codSeqNF}`,
      {
        flg_conferida: !conferida,
      },
    );

    if (nfConferida.data.success) {
      toast.success(nfConferida.data.message);
      setUpdate(false);

      nextItem();
      setShowSearch(false);
    }
  }, [codSeqNF, conferida, nextItem]);

  const aplicarAlteracoes = useCallback(async () => {
    itensNF.forEach((item: any, index: number) => {
      itensNF[index].vendaPraticar = getValues(
        `venda_praticar_${item.cod_produto}`,
      );
    });
    const nfConferidaItens = await api.put(`/preco-entrada/`, {
      itensNF,
    });

    if (nfConferidaItens.data.success) {
      // conferir sem a mensagem de toast
      await api.put(`/preco-entrada/nf-conferida/${codSeqNF}`, {
        flg_conferida: true,
      });

      if (nfConferidaItens.status === 202) {
        toast.warning(nfConferidaItens.data.message);
      } else {
        toast.success(nfConferidaItens.data.message);
        setTimeout(() => {
          setConferida(true);
        }, 250);
      }

      reset();
      setUpdate(false);
      nextItem();
      setShowSearch(true);
    }
  }, [codSeqNF, getValues, itensNF, nextItem, reset]);

  const onKeyUpInputMoney = useCallback(
    (item: NFItens, value: any, name: string) => {
      clearTimeout(timeoutId);
      // eslint-disable-next-line react-hooks/exhaustive-deps
      timeoutId = setTimeout(() => {
        handleRowChange(item, value, name);
      }, 900);
    },
    [],
  );

  const getDanfe = useCallback(async () => {
    const { num_chave_acesso, cod_loja } = getValues();
    if (num_chave_acesso) {
      try {
        const { data } = await api.get('/transmitir-nfe/gerar-danfe-entrada', {
          params: {
            cod_loja,
            chave_nfe: num_chave_acesso,
          },
        });
        if (data.success) {
          setPdf(data.danfe);
          setIsOpenModal(true);
        }
      } finally {
        setLoadingPDF(false);
      }
    } else {
      setLoadingPDF(false);
      toast.warning('Não foi possivel visualizar a DANFE');
    }
  }, [getValues]);

  return (
    <AlteracaoPrecoEntradaFornecedorContext.Provider
      value={{
        setValue,
        errors,
        control,
        register,
        getValues,
        reset,
        setFocus,
        selectedRow,
        conferida,
        loadingCalc,
        setLoader,
        loja,
        codFornecedor,
        fornecedor,
        showSearch,
        onRowClick,
        loader,
        isUpdate,
        resetFormData,
        setShowSearch,

        cnpj,
        numNf,
        entrada,
        loadingSug,
        itensNF,

        handleRowClick,
        validateNFConferida,
        descartarAlteracoes,
        marcarNFConferida,
        aplicarAlteracoes,
        assumeValor,
        selectedRowIndex,
        onKeyUpInputMoney,
        simulador,
        codSeqNF,
        user,
        isOpenModal,
        setIsOpenModal,
        getDanfe,
        pdf,
        setPdf,

        loadingPDF,
        setLoadingPDF,
      }}
    >
      {children}
    </AlteracaoPrecoEntradaFornecedorContext.Provider>
  );
};

export const useAlteracaoPrecoEntradaFornecedor =
  (): AlteracaoPrecoEntradaFornecedorContextData =>
    useContext(AlteracaoPrecoEntradaFornecedorContext);
