import React from 'react';
import { CustoContainer } from './styles';
import { NFItens } from '~/pages/AlteracaoPrecoEntradaFornecedor/types';
import { formatCurrencyAsText } from '~/utils/functions';
import { format } from 'date-fns';
import { createDateWithoutTimezone } from '~/utils/createDateWithoutTimezone ';

interface CardCustoProps {
  item: NFItens;
}

export const CardCusto: React.FC<CardCustoProps> = ({ item }) => {
  const custoRepNf = formatCurrencyAsText(item?.val_custo_rep_nf || 0);
  const custoRep = formatCurrencyAsText(item?.val_custo_rep || 0);
  const custoRepAnt = formatCurrencyAsText(item?.val_custo_rep_ant || 0);
  const dtaUltAlt = item.dta_ult_alt_custo_rep
    ? format(createDateWithoutTimezone(item.dta_ult_alt_custo_rep), 'dd/MM/yy')
    : '';

  const handleCorLoja = (val_custo_rep_nf: number, val_custo_rep: number) => {
    if (val_custo_rep_nf !== val_custo_rep) return 'red';
    return 'blue';
  };

  return (
    <CustoContainer>
      <div className="card-header-container">
        <span>Custo</span>
        <span>NF</span>
        <span>LJ</span>
      </div>
      <div className="card-body-container">
        <div className="body-reposicao">
          <span>Reposição</span>
          <span style={{ color: 'blue' }}>{custoRepNf}</span>
          <span
            style={{
              color: handleCorLoja(item?.val_custo_rep_nf, item?.val_custo_rep),
            }}
          >
            {custoRep}
          </span>
        </div>
        <div className="body-anterior">
          <span>Anterior</span>
          <span>{custoRepAnt}</span>
        </div>
        <div className="body-dta">
          <span>Dta. Últ Alt.</span>
          <span>{dtaUltAlt}</span>
        </div>
      </div>
    </CustoContainer>
  );
};
