import React, { ChangeEvent } from 'react';

import { CardGroup, Card, Spinner } from 'react-bootstrap';
import { InputPercent, InputMoney } from '~/components/NovosInputs';
import { formatCurrencyAsText } from '~/utils/functions';
import { Col, Row } from 'react-bootstrap';
import moment from 'moment';

import { useAlteracaoPrecoEntradaFornecedor } from '../../AlteracaoPrecoEntradaFornecedorContext';
import { CardCusto } from './CardCusto';

const Cards: React.FC = () => {
  const {
    register,
    control,
    errors,
    loadingCalc,
    simulador,
    assumeValor,
    selectedRow,
  } = useAlteracaoPrecoEntradaFornecedor();

  return (
    <Row style={{ marginTop: '20px' }}>
      <Col sm={12} md={12}>
        <CardGroup>
          <CardCusto item={selectedRow} />
          <Card className="margemContainer">
            <Card.Header
              style={{ textAlign: 'center', backgroundColor: '#d0d6f2' }}
            >
              Margem
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Referência:{' '}
                {selectedRow.val_margem.toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
                %
              </Card.Text>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}
              >
                <Card.Text style={{ marginTop: '-0.5rem' }}>
                  Calculada: {selectedRow.calcMargVda || '0,00'}%
                </Card.Text>
                {loadingCalc && (
                  <Card.Text style={{ marginTop: '-0.8125rem' }}>
                    <Spinner animation="border" size="sm" />
                  </Card.Text>
                )}
              </div>

              <Card.Text style={{ marginTop: loadingCalc ? '-8px' : '8px' }}>
                Atual:{' '}
                {formatCurrencyAsText(Number(selectedRow?.calcMargVdaAtual))}%
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="vendaContainer" style={{ maxWidth: '25%' }}>
            <Card.Header
              style={{ textAlign: 'center', backgroundColor: '#ffe6c0' }}
            >
              Venda
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Diverg. Sug:{' '}
                {formatCurrencyAsText(Number(selectedRow?.divergenciaSugestao))}
                %
              </Card.Text>
              <Card.Text style={{ marginTop: '-8px' }}>
                Dta. Alt. Venda:{' '}
                {moment(selectedRow?.dta_ult_alt_preco_base).format(
                  'DD/MM/YYYY',
                )}
              </Card.Text>
              <Card.Text style={{ marginTop: '-8px' }}>
                Anterior:{' '}
                {formatCurrencyAsText(
                  Number(selectedRow?.val_anterior_preco_base),
                )}
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="icmsRedContainer">
            <Card.Header
              style={{ textAlign: 'center', backgroundColor: '#CBCCCF' }}
            >
              ICMS e Red. BC.
            </Card.Header>
            <Card.Body>
              <Card.Text className="text-container">
                Entr: <p>{selectedRow?.des_info_icms_entrada}</p>
              </Card.Text>
              <Card.Text className="text-container">
                Saída: <p>{selectedRow?.des_info_icms_saida}</p>
              </Card.Text>
            </Card.Body>
          </Card>
          <Card className="simuladorContainer">
            <Card.Header
              style={{ textAlign: 'center', backgroundColor: '#b7e5c1' }}
            >
              Simulador
            </Card.Header>
            <Card.Body>
              <section className="section-input">
                <div className="text-content">
                  <Card.Text>Mark-up:</Card.Text>
                  <span className="text-preco">
                    <Card.Text>Preço Venda:</Card.Text>
                  </span>
                </div>
                <div className="containerInput">
                  <InputPercent
                    label=""
                    placeholder=""
                    max={100}
                    min={0}
                    name="markUp"
                    onInput={(event: ChangeEvent<HTMLInputElement>) => {
                      if (event.target.value.length <= 6)
                        simulador('Mark-up', event, selectedRow);
                    }}
                    showIcon
                    register={register}
                    control={control}
                    isError={!!errors.markUp}
                  />
                  <InputMoney
                    className="form-control inputMoney"
                    label=""
                    placeholder=""
                    onInput={(event: ChangeEvent<HTMLInputElement>) => {
                      simulador('precoVenda', event, selectedRow);
                    }}
                    max={9999}
                    min={0}
                    name="precoVenda"
                    register={register}
                    isError={!errors.precoVenda}
                    showIcon
                    control={control}
                  />
                </div>
              </section>
              <div className="div-button">
                <button type="button" onClick={() => assumeValor()}>
                  Assume valor
                </button>
              </div>
            </Card.Body>
          </Card>
        </CardGroup>
      </Col>
    </Row>
  );
};

export default Cards;
