import React from 'react';

import AlteracaoPrecoEntradaFornecedorContent from './AlteracaoPrecoEntradaFornecedorContent';
import { AlteracaoPrecoEntradaFornecedorProvider } from './AlteracaoPrecoEntradaFornecedorContext';

const AlteracaoPrecoEntradaFornecedor: React.FC = () => {
  return (
    <AlteracaoPrecoEntradaFornecedorProvider>
      <AlteracaoPrecoEntradaFornecedorContent />
    </AlteracaoPrecoEntradaFornecedorProvider>
  );
};

export default AlteracaoPrecoEntradaFornecedor;
