import React, { useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useAlteracaoPrecoEntradaFornecedor } from '../../../AlteracaoPrecoEntradaFornecedorContext';

export const LeitorPDF: React.FC = () => {
  const { pdf, loadingPDF } = useAlteracaoPrecoEntradaFornecedor();
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess() {
    setPageNumber(1);
  }
  return (
    <>
      <Document
        className="pdf"
        renderMode="svg"
        loading=""
        file={loadingPDF ? [] : pdf}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page renderMode="svg" width={1080} pageNumber={pageNumber} />
      </Document>
    </>
  );
};
