import styled from 'styled-components';

export const CustoContainer = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid rgba(0, 0, 0, 0.125);
  border-right: none;

  @media screen and (max-width: 1199px) {
    .custoContainer {
      min-width: 33%;
    }
  }

  @media screen and (max-width: 991px) {
    .custoContainer {
      min-width: 100%;
    }
  }

  .card-header-container {
    display: grid;
    grid-template-columns: 40% 25% 35%;
    align-items: center;

    background-color: #f3cac9;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);

    span {
      padding: 0.5rem 1rem;
    }

    > span:not(:first-child) {
      display: inline-flex;
      justify-content: center;
    }

    > span:not(:last-child) {
      border-right: 1px solid rgba(0, 0, 0, 0.205);
    }
  }

  .card-body-container {
    display: flex;
    flex-direction: column;

    .body-reposicao {
      display: grid;
      grid-template-columns: 40% 25% 35%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.205);

      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem 1rem;
      }

      > span:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.205);
      }
    }

    .body-anterior,
    .body-dta {
      display: grid;
      grid-template-columns: 65% 35%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.205);

      span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.5rem 1rem;
      }

      > span:not(:first-child) {
        align-items: center;
      }

      > span:not(:last-child) {
        border-right: 1px solid rgba(0, 0, 0, 0.205);
      }
    }
  }
`;
