import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 1.25rem;

  .svg {
    svg {
      margin: 0 auto;
    }
  }

  .icmsRedContainer {
    .text-container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .simuladorContainer {
    .section-input {
      display: flex;
      flex-direction: row;

      @media screen and (max-width: 1199px) {
        justify-content: space-between;
        margin-bottom: 10px;
      }

      .text-content {
        .text-preco {
          background-color: black;
        }
      }
    }

    .div-button {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        background-color: #28a745;
        color: white;
        border: none;
        border-radius: 0.1875rem;
        padding: 0.5rem;
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1199px) {
    .custoContainer {
      min-width: 33%;
    }

    .simuladorContainer {
      min-width: 33%;
    }

    .vendaContainer {
      min-width: 34%;
    }

    .icmsRedContainer {
      min-width: 33%;
    }
  }
  @media screen and (max-width: 991px) {
    .custoContainer {
      min-width: 50%;
    }

    .simuladorContainer {
      min-width: 100%;
      margin-top: 20px;
    }

    /* .margemContainer {
      min-width: 50%;
    } */

    .vendaContainer {
      min-width: 100%;
    }
  }

  .moneyTable {
    margin-top: -0.875rem;
  }

  .containerInput {
    .form-label {
      display: none;
    }

    input {
      max-height: 30px;
      margin-top: -10px;
    }

    .container-icon {
      height: 100% !important;
      margin-top: -10px;
      position: absolute;
      min-height: 26px;
      top: 2px;
      bottom: 0;
    }

    .container-money {
      height: 95% !important;
      margin-top: -10px;
      position: absolute;
      min-height: 26px;
      top: 2px;
      bottom: 0;
      margin-top: -2px;
    }

    .inputMoney {
      margin-top: -2px;
    }
  }

  .table-exibition {
    .itensTable {
      svg {
        font-size: 24px;
      }
    }

    .td-style {
      white-space: nowrap;
    }
    .editado {
      color: #48778c !important;
      background: #dcfcfb !important;
    }

    .table tbody {
      border: 1px solid #dee2e6 !important;
    }
    .table tbody tr td {
      color: #757575;
      /* background: #e3e3e3; */
      border-left: 1px solid #dee2e6;
    }
    .table tbody tr td input {
      width: 100%;
      height: 100%;
      border: none;
      box-sizing: border-box;
      &:disabled {
        border: none !important;
        background-color: transparent !important;
      }
    }
    .table thead tr th {
      padding-left: 0px;
      border: none;
      font-size: 0.8571428571428571em;
      font-style: inherit;
      // color: #8850bf;
      font-weight: 600 !important;
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    }
    table {
      .cellModify {
        position: sticky;
        left: 0;
        top: 0;
        min-width: 100px;
        // background-color: #57069e;
        z-index: 3;
        text-align: center;
      }
    }
  }
`;

export const ButtonCustom = styled.button`
  width: 150px;
  height: 40px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 3px;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  outline: none !important;
`;
